import { useQuery } from 'react-query';

import { BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

export const VET_INITIATIVES_QUERY = 'vetInitiatives';

export function useVetInitiatives({ vetId } = {}, options = {}) {
  return useQuery(
    [VET_INITIATIVES_QUERY, vetId],

    () =>
      fetchWrapper(`/vets/${vetId}/initiatives`, {
        baseApi: BASE_API,
        transformData: data => data[0],
      }),

    {
      enabled: !!vetId,
      placeholderData: {},
      ...options,
    }
  );
}

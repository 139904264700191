import { Auth } from '@aws-amplify/auth';

import { configureAmplify, USER_SRP_AUTH } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

export function logIn(email, password) {
  configureAmplify(USER_SRP_AUTH);

  return Auth.signIn(email.toLowerCase(), password).catch(error => {
    if (error.code === 'UserNotFoundException') {
      return Auth.signIn(email, password);
    }
    throw error;
  });
}

export function logOut() {
  return Auth.signOut();
}

export function getVetUserSelf() {
  return fetchWrapper('/users/self', {
    isAuth: true,
  });
}

export function getVetUser(id) {
  return fetchWrapper(`/users/${id}`, {
    isAuth: true,
  });
}

import { useMutation, useQueryClient } from 'react-query';

import { BASE_API } from '@pumpkincare/portal-config';
import { fetchWrapper } from '@pumpkincare/portal-shared';

import { VET_INITIATIVES_QUERY } from './vet-initiatives-query';

/**
 * Returns RQ methods and the mutation for patching a vet initiative. It will also invalidate the vet initiative query
 * @param vetId
 * @param vetInitiativeId
 * @returns {UseMutationResult<unknown, unknown, void, unknown>}
 */

export function useMutatePatchVetInitiative(vetId, vetInitiativeId) {
  const queryClient = useQueryClient();

  /**
   * Return the RQ method for patching a vet initiative
   * @param {string} hasDashboard
   */
  return useMutation(
    ({ hasDashboard }) =>
      fetchWrapper(`/vets/${vetId}/initiatives/${vetInitiativeId}`, {
        method: 'PATCH',
        baseApi: BASE_API,
        body: {
          has_dashboard: hasDashboard,
        },
      }),

    {
      // Always re fetch after error or success
      onSettled: () => queryClient.invalidateQueries([VET_INITIATIVES_QUERY, vetId]),
    }
  );
}
